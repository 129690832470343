import {Box, Button, Grid, Typography} from "@mui/material";
import Verify from "../../assets/images/gif/email-auth.gif";
import React from "react";
import "./style.css"
import {ResendEmail} from "../../redux/auth/actions";
import AppBackButton from "../../components/AppBackButton";
import {Logout} from "../../redux/auth/actions";
export default function WaitingToVerifyEmail (){
    return(
        <Grid container style={{height: "100vh"}}>
            <Grid item xs={12} className="verify-box">
                <Box className="back-button-icon" sx={{left:{lg:"50px" , xs:"20px"}}}>
                    <AppBackButton onClick={()=>Logout()}/>
                </Box>
                <Box className="verify">
                    <img
                        src={Verify}
                        alt="verify-page"
                    />
                    <Typography
                        sx={{
                            fontSize: {md: "2rem", sm: "1.25rem", xs: "1rem"},
                            fontWeight: "700",
                            textAlign: "center",
                        }}
                    >
                        Please check your email inbox to verify your account.
                    </Typography>
                    <Button onClick={ResendEmail}>
                        Resend
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}