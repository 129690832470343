import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import React from "react";

interface RHFCheckbox{
    attribute:{
        name:any,
        label?:string
    }
}
const RHFCheckbox: React.FC<RHFCheckbox> =({attribute, ...other})=> {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      label={attribute.label}
      control={
        <Controller
          name={attribute.name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={!!field.value} />}
        />
      }
      {...other}
    />
  );
}

export default React.memo(RHFCheckbox)

// ----------------------------------------------------------------------
//
// RHFMultiCheckbox.propTypes = {
//   name: PropTypes.string,
//   options: PropTypes.arrayOf(PropTypes.string),
// };
//
// export function RHFMultiCheckbox({ name, options, ...other }) {
//   const { control } = useFormContext();
//
//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field }) => {
//         const onSelected = (option) =>
//           field.value.includes(option) ? field.value.filter((value) => value !== option) : [...field.value, option];
//
//         return (
//           <FormGroup>
//             {options.map((option) => (
//               <FormControlLabel
//                 key={option}
//                 control={
//                   <Checkbox
//                     checked={field.value.includes(option)}
//                     onChange={() => field.onChange(onSelected(option))}
//                   />
//                 }
//                 label={option}
//                 {...other}
//               />
//             ))}
//           </FormGroup>
//         );
//       }}
//     />
//   );
// }
