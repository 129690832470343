import store from "../../redux/store";

export const CHAT_LIST = "CHAT_LIST"
export const CREATE_GROUP_CHAT = "CREATE_GROUP_CHAT"
export const SEND_MESSAGE = "SEND_MESSAGE"
export const SEND_VOICE_MESSAGE = "SEND_VOICE_MESSAGE"
export const SEND_FILE_MESSAGE = "SEND_FILE_MESSAGE"
export const SET_MESSAGE_BOX_STATE = "SET_MESSAGE_BOX_STATE"
export const SET_CHAT = "SET_CHAT"
export const SET_SOCKET_ID = "SET_SOCKET_ID"
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT"
export const SET_MESSAGE = "SET_MESSAGE"
export const GET_CHAT = "GET_CHAT"
export const LEAVE_CHAT = "LEAVE_CHAT"
export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT"
export const DO_REMOVE_PARTICIPANT = "DO_REMOVE_PARTICIPANT"
export const READ_CHAT = "READ_CHAT"
export const GET_PUBLIC_CHAT_LIST = "GET_PUBLIC_CHAT_LIST"
export const SEARCH_CHAT = "SEARCH_CHAT";
export const SHARE_POST = "SHARE_POST";
export const JOIN_CHAT = "JOIN_CHAT";
export const DELETE_CHAT = "DELETE_CHAT";

export const GetChatList = () => {
    store.dispatch({
        type: CHAT_LIST,
    })
}
export const GetPublicChatList = () => {
    store.dispatch({
        type: GET_PUBLIC_CHAT_LIST,
    })
}
export const CreateGroupChat = (data: any) => {
    store.dispatch({
        type: CREATE_GROUP_CHAT,
        payload: {data}
    })
}
export const SendMessage = (data: any) => {
    store.dispatch({
        type: SEND_MESSAGE,
        payload: {data}
    })
}
export const SendVoiceMessage = (data: any) => {
    store.dispatch({
        type: SEND_VOICE_MESSAGE,
        payload: {data}
    })
}
export const GetChat = (data: any) => {
    store.dispatch({
        type: GET_CHAT,
        payload: {data}
    })
}
export const DeleteChat = (data: any) => {
    store.dispatch({
        type: DELETE_CHAT,
        payload: {data}
    })
}
export const LeaveChat = (id: any) => {
    store.dispatch({
        type: LEAVE_CHAT,
        payload: {id}
    })
}
export const SetMessage = (message: any) => {
    store.dispatch({
        type: SET_MESSAGE,
        message
    })
}
export const SetSocketID = (data: any) => {
    store.dispatch({
        type: SET_SOCKET_ID,
        data
    })
}
export const SetCurrentChat = (data: any) => {
    store.dispatch({
        type: SET_CURRENT_CHAT,
        data
    })
}
export const RemoveParticipant = (data: any) => {
    store.dispatch({
        type: REMOVE_PARTICIPANT,
        payload: {data}
    })
}
export const Read = (data: any) => {
    store.dispatch({
        type: READ_CHAT,
        payload: {data}
    })
}
export const Search = (data: any, search: any) => {
    store.dispatch({
        type: SEARCH_CHAT,
        data: data.data,
        search
    })
}
export const SharePost = (post: any, user: any) => {
    store.dispatch({
        type: SHARE_POST,
        payload: {
            post,
            user
        }
    })
}
export const JoinChat = (data: any) => {
    store.dispatch({
        type: JOIN_CHAT,
        payload: {data}

    })
}
export const SendFile = (data: any) => {
    store.dispatch({
        type: SEND_FILE_MESSAGE,
        payload: {data}

    })
}
export const SetMessageBoxState = (data: any) => {
    store.dispatch({
        type: SET_MESSAGE_BOX_STATE,
        data

    })
}
