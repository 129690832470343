import {SET_USER_POST_LIST} from "./action";

interface SetInitialized {
    type: string;
    data: any;
    links:object
}

type ActionType = SetInitialized;

const initializedState = {
    data:null
};

const reducer = (state = initializedState, action: ActionType) => {
    const { data , links} = action;
    switch (action?.type) {
        case SET_USER_POST_LIST:
            return {data , links};
        default:
            return state;
    }
};

export default reducer;
