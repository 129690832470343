import {Box, Grid} from "@mui/material";
import React from "react";
import AuthSignInForm from "../../sections/AuthSignInForm";
import AuthSlider from "../../sections/AuthSlider";
import AuthLayout from "./index";

export default function SignIn() {
    return (
           <AuthLayout>
               <Box className="auth">
                   <Grid container>
                       <Grid item lg={6} xs={12} sx={{height:"100vh"}}>
                           <AuthSignInForm/>
                       </Grid>
                       <Grid item lg={6} sx={{height:{lg: "100vh"}, display: {xs:'none', lg:'block'}}}>
                           <AuthSlider/>
                       </Grid>
                   </Grid>
               </Box>
           </AuthLayout>
    )
}
