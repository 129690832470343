// types.d.ts
import {
    INITIALIZE,
} from "../actions";
import {
    INFORMATION_SUCCESS, LOGOUT_SUCCESS, SELECT_TAG_SUCCESS,
    SIGN_IN_SUCCESS,
    SIGN_IN_WITH_FACEBOOK_SUCCESS,
    SIGN_IN_WITH_GOOGLE_SUCCESS,
    SIGN_UP_SUCCESS, TAG_SELECTED_SUCCESS,
    VERIFY_EMAIL_SUCCESS
} from "./action";


// reducer.ts
interface SetInitialized {
    type: string;
    data: {
        initialized: boolean,
        isAuthenticated: boolean,
        isVerified: boolean,
        isSelectedTag: any,
        tags: any,
        user: any,
    }
}

type ActionType = SetInitialized;

const initialized = () => {
    return {
        initialized: false,
        isAuthenticated: false,
        isVerified: false,
        isSelectedTag: false,
        tags: null,
        user: null,
    }
};
const logout = () => {
    return {
        initialized: true,
        isAuthenticated: false,
        isVerified: false,
        isSelectedTag: false,
        tags: null,
        user: null,
    }
};

const reducer = (state = initialized(), action: ActionType) => {

    const {data} = action;
    const DEFAULT: any = logout();
    switch (action?.type) {
        case INITIALIZE:
            return data
        case SIGN_IN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isVerified: data.isVerified,
                isSelectedTag: data.isSelectedTag,
                user: data.user,
                tags: data.tags
            }
        case SIGN_IN_WITH_GOOGLE_SUCCESS:
            const b =  {
                ...state,
                isAuthenticated: true,
                isVerified: true,
                isSelectedTag: data.isSelectedTag,
                user: data.user,
                tags: data.tags
            }
            console.log(b)
            return b
        case SIGN_IN_WITH_FACEBOOK_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isVerified: true,
                isSelectedTag: data.isSelectedTag,
                user: data.user,
                tags: data.tags
            }
        case SIGN_UP_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                user: data.user,
            }
        }
        case VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                isVerified: true,
            }
        case INFORMATION_SUCCESS:
            return {
                ...state,
                user: data?.user
            }
        case SELECT_TAG_SUCCESS:
            return {
                ...state,
                isSelectedTag: data?.isSelectedTag,
                tags:data.tags
            }
        case TAG_SELECTED_SUCCESS:
            return {
                ...state,
                tags:data.tags
            }
        case LOGOUT_SUCCESS:
            return DEFAULT
        default:
            return state;
    }
};

export default reducer;
