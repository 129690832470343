import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Pusher, {Channel} from "pusher-js";
import {PUSHER_CONFIG} from "../config";
import {SetNotification} from "../redux/notifications/action";
import axiosInstance from "../boot/axios";
export default function NotificationProvider({children}: any) {
    const token:any = window.localStorage.getItem("token")
    const state: any = useSelector(state => state)
    const [pusher, setPusher]: any = useState(null)
    const loadPusher = () => {
        return new Pusher("uxgvldvjgvcc0rnnsuac", PUSHER_CONFIG())
    }
    useEffect(() => {
        const connection = loadPusher();
        setPusher(connection)

    }, []);
    useEffect(() => {
            if (pusher  && state?.initialized?.user?.uuid) {
                const channel: Channel = pusher.subscribe(`private-user.${state?.initialized?.user?.uuid}`)
                channel.bind('notification.received', (data: any) => {
                    SetNotification(data);
                });
                axiosInstance.defaults.headers.post["X-Socket-ID"] = pusher?.connection?.socket_id;

            }
    }, [state?.initialized?.user?.uuid]);

    return (
        <>

            {children}
        </>
    )
}
