import React from "react";
import {Box, CircularProgress} from "@mui/material";

interface Loader{
    isLoading?:boolean
}
const Loader:React.FC<Loader> = ({ isLoading }) => {
    return (
        <Box className="loading-screen">
            <CircularProgress color="primary" />
        </Box>
    );
};

export default Loader;
