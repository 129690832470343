import React from "react";
import {InputLabel, TextField} from "@mui/material";

interface AppInputProps {
    label?: string;
    value: any | undefined;
    onChange?: (value: string) => void,
    className?: string,
    name?: string
    type?: string,
    placeholder?: string,
    color?: "white" | "gray",
    sx?: any
    onKeyUp?: (value: string) => void
}

const AppInput: React.FC<AppInputProps> = ({
                                               label,
                                               value,
                                               onChange,
                                               className,
                                               name,
                                               sx,
                                               type,
                                               color = "gray",
                                               placeholder,
                                               onKeyUp,
                                               ...props
                                           }) => {
    const handleChange = (event: any) => {
        if (onChange) onChange(event.target.value);
    };

    const handleKeyUp = (event: any) => {
        if (onKeyUp)
            onKeyUp(event.target.value);
    };
    return (
        <React.Fragment>
            <InputLabel
                sx={{fontSize: "14px", color: "#213555", marginBottom: "8px", marginLeft: "8px"}}>{label}</InputLabel>
            <TextField
                value={value}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                type={type}
                name={name}
                className={className}
                placeholder={placeholder}
                sx={{
                    background: color === "gray" ? "#F0F0F0" : "#FFF",
                    borderRadius: "50px",
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "unset !important",
                    },
                    sx
                }}
                {...props}
            />
        </React.Fragment>
    )
}

export default React.memo(AppInput);
