import React from "react";
import BackButton from "../../assets/images/svg/back-icon.svg";
import {Button} from "@mui/material";
import {useNavigate} from "react-router";

interface BackInterface {
    onClick?: any,
    url?: any
}

const AppBackButton: React.FC<BackInterface> = ({url = null, onClick = null}) => {
    const navigate = useNavigate()
    return (
        <Button onClick={onClick ? onClick : () => navigate(url)}>
            <img src={BackButton} alt="back-button"/>
        </Button>
    )
}

export default React.memo(AppBackButton)
