import {AxiosResponse} from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {GET, POST} from "../../api";
import ApiRouts from "../../boot/api-routes";
import {CLOSE_NOTIFICATION, FETCH_NOTIFICATION_LIST, GET_NOTIFICATION_LIST} from "../actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {AppSwal} from "../../global/functions";

export function* GetNotification(){
    try{
        const response :AxiosResponse = yield call(GET , ApiRouts?.NOTIFICATIONS , {})
        if(response){
            yield put({
                type:GET_NOTIFICATION_LIST,
                data:response.data.data
            })
        }
    }catch(error){
        console.log(error)
    }
}

export function* ReadNotification(action: PayloadAction<{id:any}>){
    try{
        const response :AxiosResponse = yield call(POST , ApiRouts?.NOTIFICATIONS + "/" + action.payload.id + "/read" , {})
        if(response){
            yield call(GetNotification)
        }
    }catch(error){
        console.log(error)
    }
}

function* notificationSaga() {
    yield takeLatest(FETCH_NOTIFICATION_LIST, GetNotification);
    yield takeLatest(CLOSE_NOTIFICATION, ReadNotification);
}

export default notificationSaga;
