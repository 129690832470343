import store from "../store";

export const ADD_TAG = "ADD_TAG"
export const REMOVE_TAG = "REMOVE_TAG"
export const TOGGLE_PAGE = "TOGGLE_PAGE"
export const SELECT_TAG = "SELECT_TAG";
export const ENTER_TAG = "ENTER_TAG";
export const REMOVE_TAG_PANEL = "REMOVE_TAG_PANEL";
export const ADD_TAG_PANEL = "ADD_TAG_PANEL";
export const GET_SUGGESTED_TAG = "GET_SUGGESTED_TAG";
export const FETCH_TAG_LIST = "FETCH_TAG_LIST";
export const SET_TAG_LIST = "SET_TAG_LIST";


export const SelectTag = (tag:any ) => {
    store.dispatch({
        type: SELECT_TAG,
        payload: {tag }
    })
}

export const EnterTag = (title:any ) => {
    store.dispatch({
        type: ENTER_TAG,
        payload: {title }
    })
}

export const SuggestedTag = () => {
    store.dispatch({
        type: GET_SUGGESTED_TAG,
    })
}

export const RemoveTag = (id:any ) => {
    store.dispatch({
        type: REMOVE_TAG_PANEL,
        payload: {id}
    })
}


export const AddTag = (id:any ) => {
    store.dispatch({
        type: ADD_TAG_PANEL,
        payload: {id}
    })
}