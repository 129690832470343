import React, {useCallback} from "react";
import { Button } from "@mui/material";
import Facebook from "../../assets/images/facebook.svg";
import ApiRouts from "../../boot/api-routes";
const AppButtonFacebook = () => {
    const loginWithFacebook = useCallback(()=>{
        window.location.replace(process.env.REACT_APP_BASE_URL + ApiRouts?.SIGN_IN_WITH_FACEBOOK)
    },[])
    return (
        <Button onClick={loginWithFacebook}
                sx={{
                    color:"#fff",
                    borderRadius:"26px",
                    padding:"12px",
                    fontSize:"14px",
                    background:"#3C5A9A",
                    textTransform:"none"
                }}
                fullWidth={true}
                variant="contained">
            <img src={Facebook} alt="facebook" style={{marginRight:"8px"}}/> Sign in with Facebook
        </Button>
    );
};
export default React.memo(AppButtonFacebook);
