import {GRID_POST_ROW} from "./action";

interface ToggleInterface {
    type: string;
}

type ActionType = ToggleInterface;

const initialized = () => {
    return false
};
const reducer = (state: any = initialized(), action: ActionType) => {

    switch (action?.type) {
        case GRID_POST_ROW:
            return !state
        default:
            return state;
    }
};

export default reducer;
