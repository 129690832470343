import React from "react";
import {Box} from "@mui/material";

const AuthLayout = ({children}: any) => {

    return (
        <React.Fragment>
            <Box component="main">
                {children}
            </Box>
        </React.Fragment>
    )
}

export default React.memo(AuthLayout)
