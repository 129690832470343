import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {InputLabel} from "@mui/material";
import {Controller, useFormContext} from "react-hook-form";
import store from "../../redux/store";
import {GET_CITY} from "../../redux/actions";
import {useSelector} from "react-redux";
import Chip from "@mui/material/Chip";

interface RHFLocationProps {
    attribute: {
        label?: string;
        className?: string;
        items: any;
        displayKey: string;
        InputValue: string;
        color?: "white" | "gray";
        name: string;
        placeholder?: string;
        defaultValue?: any
    };
}

const RHFLocation: React.FC<RHFLocationProps> = ({attribute}) => {
    const {control} = useFormContext();
    const state: any = useSelector(state => state)
    const [value, setValue] = useState(attribute?.defaultValue || {name:""});
    const getOptions: any = () => {
       return attribute.items ?? [];
    }

    return (
        <React.Fragment>
            { attribute.label && (
                <InputLabel className="auth-input">
                    {attribute.label}
                </InputLabel>
            )}
            <Controller
                name={attribute.name}
                control={control}
                render={({field, fieldState: {error}}) => (
                    <Autocomplete
                        options={getOptions()}
                        getOptionLabel={(option: any) => option["name"]}
                        openOnFocus
                        value={value}
                        onChange={(event: any, newValue: any | null) => {

                            setValue(newValue)
                            field.onChange(newValue?.name??'');

                            if (newValue?.states) {
                                const selectedCities = newValue?.states || '';
                                store.dispatch({
                                    type: GET_CITY,
                                    data: {
                                        country: state?.location?.country,
                                        cities: selectedCities,
                                    }
                                })

                            } else  {
                                store.dispatch({
                                    type: GET_CITY,
                                    data: {
                                        country: state?.location?.country,
                                        cities: state?.location?.cities,
                                    },
                                });

                            }
                        }}
                        renderInput={(params: any) => (
                            <TextField
                                {...field}
                                {...params}
                                error={!!error}
                                placeholder={attribute.placeholder}
                                helperText={error?.message}
                                sx={{
                                    "& .MuiInputBase-root": {
                                        background: attribute.color === "gray" ? "#F0F0F0" : "#FFF",
                                        borderRadius: "50px",
                                        width: "100%",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "unset !important",
                                    },
                                }}
                            />
                        )}

                    />
                )}
            />
        </React.Fragment>
    );
};

export default React.memo(RHFLocation);
