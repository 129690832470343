import {SET_CHAT_LIST} from "../actions";
import {SET_CHAT, SET_MESSAGE} from "./action";
import messages from "../../pages/app/dashboard/components/Messages";

interface SetInitialized {
    type: string;
    data: any,
    message?: any
}

type ActionType = SetInitialized;

const initializedState = null

const reducer = (state:any = initializedState, action: ActionType) => {
    const {data} = action;
    switch (action?.type) {
        case SET_CHAT:
            return data;
        case SET_MESSAGE:
            let message = action?.message;
            return [
                ...state,
                message
            ];
        default:
            return state;
    }
};

export default reducer;
