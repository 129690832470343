import ApiRouts from "../boot/api-routes";
import {INITIALIZE} from "../redux/actions";
import store from "../redux/store";
import axios from "../boot/axios";

export const Initialize = () => {
    const token: any = window.localStorage.getItem("token")
        if (token) {
            axios.get(<string>ApiRouts?.GET_PROFILE).then((response) => {
                store.dispatch({
                    type: INITIALIZE,
                    data: {
                        initialized: true,
                        isAuthenticated: true,
                        isVerified: !!response?.data?.data?.email_verified_at,
                        isSelectedTag: response?.data?.data?.tags.length > 0,
                        tags: response?.data?.data?.tags,
                        user: response?.data?.data
                    }
                })
            }).catch((e:any)=>{
                store.dispatch({
                    type: INITIALIZE,
                    data: {
                        initialized: true,
                        isAuthenticated: false,
                        isVerified: false,
                        isSelectedTag: false,
                        tags: null,
                        user: null
                    }
                })
            })

        }else {
            store.dispatch({
                type: INITIALIZE,
                data: {
                    initialized: true,
                    isAuthenticated: false,
                    isVerified: false,
                    isSelectedTag: false,
                    tags: null,
                    user: null
                }
            })
        }



}
