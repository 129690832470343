import {Box, Grid} from "@mui/material";
import React from "react";
import AuthSlider from "../../../sections/AuthSlider";
import AuthEnterTag from "../../../sections/AuthEnterTag";

export default function EnterTag(){
    return(
        <Box className="auth">
            <Grid container>
                <Grid item lg={6} xs={12} sx={{height:"100vh"}}>
                    <AuthEnterTag/>
                </Grid>
                <Grid item lg={6} sx={{height:{lg: "100vh"}, display: {xs:'none', lg:'block'}}}>
                    <AuthSlider/>
                </Grid>
            </Grid>
        </Box>
    )
}
