import {useSelector} from "react-redux";
import {PATH_DASHBOARD} from "../routes/paths";
import React from "react";
import {Navigate} from "react-router-dom";
import Loader from "../components/Loader/Loader";


export default function GuestGuard({children}: any) {
    const state:any = useSelector(state => state)
    if (!state.initialized.initialized) {
        return <Loader/>
    }
    if (state.initialized.isAuthenticated ) {
        return <Navigate to={PATH_DASHBOARD.profile} />
    }

    return <>{children}</>;
}

