import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {GET, POST} from "../../api";
import ApiRouts from "../../boot/api-routes";
import {SELECT_TAG_SUCCESS} from "../global/action";
import store from "../store";
import {AppSwal} from "../../global/functions";
import {
    ADD_TAG_PANEL,
    ENTER_TAG, GET_SUGGESTED_TAG, REMOVE_TAG_PANEL,
    SELECT_TAG,
} from "./action";
import {SUGGESTED_TAG} from "../actions";
import {Initialize} from "../../guards/initialize";

export function* SelectTag(action: PayloadAction<{ tag: any[] }>) {
    try {
        const formData = new FormData();
        action.payload.tag.forEach((item) => {
            formData.append('title[]', item?.title);
        });
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.ENTER_TAG, formData);
        if (response) {
            yield put({
                type: SELECT_TAG_SUCCESS, data: {
                    isSelectedTag: true,
                    tags: response.data.data
                }
            })

        }
    } catch (error) {
        console.log(error)
    }
}

export function* EnterTag(action: PayloadAction<{ title: any[] }>) {
    try {

        const formData = new FormData();
        action.payload.title.forEach((item: { title: string | Blob; }) => {
            const titleString = typeof item.title === 'string' ? item.title : item.title.toString();
            formData.append('title[]', titleString);
        });
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.ENTER_TAG, formData);
        if (response) {
            store.dispatch({
                type: SELECT_TAG_SUCCESS,
                data: {
                    isSelectedTag: true,
                    tags: response.data.data
                }
            });
            AppSwal({value: "The desired tags have been successfully added", type: 'success'});
        }
    } catch (error) {
        console.log(error)
    }
}

export function* GetSuggestedTag() {
    try {
        const response: AxiosResponse = yield call(GET, <string>ApiRouts?.GET_SUGGESTED_TAG , {});
        if (response) {
            store.dispatch({
                type: SUGGESTED_TAG,
                data: {
                    data: response.data?.data
                }
            });
        }
    } catch (error) {
        console.log(error)
    }
}

export function* RemoveTag(action: PayloadAction<{ id: any }>) {
    try {

        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.REMOVE_TAG, {tag:action?.payload?.id});
        if (response) {
            yield call(Initialize)
            yield call(GetSuggestedTag)
            AppSwal({value: "Selected tag successfully removed.", type: 'success'});
        }
    } catch (error) {
        console.log(error)
    }
}

export function* AddTag(action: PayloadAction<{ id: any }>) {
    try {

        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.ADD_TAG, {tag:action?.payload?.id});
        if (response) {
            yield call(Initialize)
            yield call(GetSuggestedTag)
            AppSwal({value: "New tag successfuly added.", type: 'success'});
        }
    } catch (error) {
        console.log(error)
    }
}


function* tagSaga() {
    yield takeLatest(SELECT_TAG, SelectTag);
    yield takeLatest(ENTER_TAG, EnterTag);
    yield takeLatest(GET_SUGGESTED_TAG, GetSuggestedTag);
    yield takeLatest(REMOVE_TAG_PANEL, RemoveTag);
    yield takeLatest(ADD_TAG_PANEL, AddTag);
}

export default tagSaga;

