import {
    call,
    takeLatest,
    put,
} from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import {PayloadAction} from "@reduxjs/toolkit";
import {
    SIGN_IN,
    SIGN_UP,
    FORGET_PASSWORD,
    SIGN_IN_WITH_GOOGLE,
    SIGN_IN_WITH_FACEBOOK,
    RESET_PASSWORD,
    VERIFY_EMAIL,
    LOGOUT,
    RESEND_EMAIL
} from "../actions";
import ApiRouts from "../../boot/api-routes";
import {AppSwal, setToken} from "../../global/functions";
import {GET, POST} from "../../api";
import {PATH_AUTH, PATH_DASHBOARD} from "../../routes/paths";
import {router} from '../../routes';
import {
    LOGOUT_SUCCESS,
    SIGN_IN_SUCCESS,
    SIGN_IN_WITH_FACEBOOK_SUCCESS,
    SIGN_IN_WITH_GOOGLE_SUCCESS, SIGN_UP_SUCCESS,
    VERIFY_EMAIL_SUCCESS
} from "../global/action";

export function* SignIn(action: PayloadAction<{ email: any, password: any }>) {
    try {
        const response: AxiosResponse = yield call(POST, ApiRouts?.SIGN_IN, action.payload);
        if (response) {
            setToken(response.data.token)
            yield put({
                type: SIGN_IN_SUCCESS, data: {
                    isVerified: !!response.data.data.email_verified_at,
                    isSelectedTag: response.data.data.tags.length>0,
                    user: response.data.data,
                    tags: response.data.data.tags
                }
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* SignInWithGoogle(action: PayloadAction<{ params: any }>) {
    try {
        const response: AxiosResponse = yield call(GET, ApiRouts?.VALIDATE_GOOGLE, action.payload.params);
        if (response) {
            yield put({
                type: SIGN_IN_WITH_GOOGLE_SUCCESS, data: {
                    isSelectedTag: response.data.data.tags.length>0,
                    user: response.data.data,
                    tags: response.data.data.tags
                }
            })

            setToken(response.data.token)
        }
    } catch (error) {
        console.log(error)
    }
}


export function* SignInWithFacebook(action: PayloadAction<{ params: any }>) {

    try {
        const response: AxiosResponse = yield call(GET, ApiRouts?.VALIDATE_FACEBOOK, action.payload.params);
        if (response) {
            setToken(response.data.token)
            AppSwal({value: response?.data?.status, type: "success"})
            yield put({
                type: SIGN_IN_WITH_FACEBOOK_SUCCESS, data: {
                    isSelectedTag: response.data.data.tags.length>0,
                    user: response.data.data,
                    tags: response.data.data.tags
                }
            })

        }
    } catch (error) {
        console.log(error)
    }
}

export function* VerifyEmail(action: PayloadAction<{ params: any }>) {
    try {
        const url = action.payload.params.verify_url + "&signature=" + action.payload.params.signature
       // const replaceUrl = url?.replace("http", "https")
        const response: AxiosResponse = yield call(GET, url, {});
        if (response) {
            yield put({type: VERIFY_EMAIL_SUCCESS, data: {}})
            router.navigate(PATH_DASHBOARD.profile)

        }
    } catch (error) {
        console.log(error)
    }
}

export function* ResendEmail() {
    try {
        const response: AxiosResponse = yield call(POST, ApiRouts?.RESEND_EMAIL, {});
        if (response) {
            AppSwal({value: response?.data?.status, type: "success"})
        }
    } catch (error) {
        console.log(error)
    }
}

export function* SignUp(action: PayloadAction<{ email: any, password: any, password_confirmation: any }>) {

    try {
        const response: AxiosResponse = yield call(POST, ApiRouts?.SIGN_UP, action.payload);
        if (response) {
            setToken(response.data.token)
            AppSwal({value: "Please check your email inbox to verify your account", type: "success"})
            yield put({
                type: SIGN_UP_SUCCESS, data: {
                    user: response.data.data,
                }
            })

        }
    } catch (error) {
        console.log(error)
    }
}

export function* ForgetPassword(action: PayloadAction<{ email: any }>) {
    try {
        const response: AxiosResponse = yield call(POST, ApiRouts?.FORGET_PASSWORD, action.payload)
        if (response) {
            AppSwal({value: response?.data?.status, type: "success"})
        }
    } catch (error) {
        console.log(error)
    }
}

export function* ResetPassword(action: PayloadAction<{
    password: any,
    password_confirmation: any,
    email: string,
    token: string
}>) {
    try {

        const response: AxiosResponse = yield call(POST, ApiRouts?.RESET_PASSWORD, action.payload)
        if (response) {
            AppSwal({value: response?.data?.status, type: "success"})
            router.navigate(PATH_AUTH.login)
        }
    } catch (error) {
        console.log(error)
    }
}

export function* Logout(action: PayloadAction<{ navigate: any }>) {
    try {
            window.localStorage.removeItem("token")
            yield put({type: LOGOUT_SUCCESS});

    } catch (error) {
        console.log(error)
    }
}


function* authSaga() {
    yield takeLatest(SIGN_IN, SignIn);
    yield takeLatest(SIGN_IN_WITH_GOOGLE, SignInWithGoogle);
    yield takeLatest(SIGN_IN_WITH_FACEBOOK, SignInWithFacebook)
    yield takeLatest(VERIFY_EMAIL, VerifyEmail)
    yield takeLatest(RESEND_EMAIL, ResendEmail)
    yield takeLatest(SIGN_UP, SignUp);
    yield takeLatest(FORGET_PASSWORD, ForgetPassword);
    yield takeLatest(RESET_PASSWORD, ResetPassword);
    yield takeLatest(LOGOUT, Logout);
}

export default authSaga;
