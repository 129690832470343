import {Chip} from "@mui/material";
import React from "react";


interface AppTagList {
    onClick: (value: number) => void,
    icon?: React.ReactNode | null,
    item: any,
    className?:string,
    label:any
}

const AppTagList: React.FC<AppTagList> = ({onClick, icon,className, item , label}) => {
    return (
        <Chip
            key={item?.id}
            avatar={<React.Fragment>{icon ? icon : ""}</React.Fragment>}
            label={label}
            variant="filled"
            className={className}
            onClick={() => onClick(item)}
        />
    )
}

export default React.memo(AppTagList)