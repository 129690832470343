import {SET_CHAT_LIST} from "../actions";
import {SET_MESSAGE_BOX_STATE} from "./action";


interface SetInitialized {
    type: string;
    data: any,
}

type ActionType = SetInitialized;

const initializedState = "message-list"

const reducer = (state = initializedState, action: ActionType) => {
    switch (action?.type) {
        case SET_MESSAGE_BOX_STATE:
            return action.data
        default:
            return state;
    }
};

export default reducer;
