import store from "../store";
import {CLOSE_NOTIFICATION, FETCH_NOTIFICATION_LIST} from "../actions";
export const SET_NOTIFICATION  = "SET_NOTIFICATION";

export const GetNotification = () => {
    store.dispatch({
        type: FETCH_NOTIFICATION_LIST,
    })
}

export const ReadNotification = (id:any) => {
    store.dispatch({
        type: CLOSE_NOTIFICATION,
        payload:{id}
    })
}

export const SetNotification = (notification:any) => {
    store.dispatch({
        type: SET_NOTIFICATION,
        notification
    })
}
