import {Box, Button, Grid, Link, Stack, Typography} from "@mui/material";
import AppButton from "../../components/AppButton";
import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import AppInput from "../../components/AppInput";
import AppLogo from "../../components/AppLogo";
import ApiRouts from "../../boot/api-routes";
import {POST} from "../../api";
import AppTags from "../../sections/AppTags";
import AppSelectedTag from "../../sections/AppSelectedTag";
import store from "../../redux/store";
import {useSelector} from "react-redux";
import {ADD_TAG, REMOVE_TAG, TOGGLE_PAGE} from "../../redux/tags/action";
import {SelectTag} from "../../redux/tags/action";
import Logo from "../../components/AppLogo";

const AuthSelectTag = () => {
    const state: any = useSelector(state => state)
    const [tagList, setTagList] = useState<any>([])
    const [activeLetter, setActiveLetter] = useState<string | null>(null);
    const [selectTagForm, setSelectTagForm] = useState<any>({
        tag: "",
    })
    const [pages, setPages] = useState<any>({
        current_page: 1,
        from: 1,
        last_page: 1,
    })
    const startCharCode = 'A'.charCodeAt(0);
    const endCharCode = 'Z'.charCodeAt(0);
    const alphabet: string[] = [];

    for (let charCode = startCharCode; charCode <= endCharCode; charCode++) {
        alphabet.push(String.fromCharCode(charCode));
    }
    const fetchTagList = (page = 1) => {
        POST(ApiRouts?.SEARCH_TAG + "?page=" + page, {"search": selectTagForm?.tag}).then((response) => {
            if (state?.tags.length !== 0) {
                const filteredTags = response?.data?.data?.filter((tag: any) =>
                    !state?.tags.some((item: any) => item.title === tag.title)
                );
                setTagList(filteredTags);
            } else {
                setTagList(response?.data?.data);
            }

            setPages(response?.data?.meta);
        });
    };


    const handlerChangePage = (page: number) => {
        setPages((store: any) => ({...store, current_page: page}));
        fetchTagList(page);
    };

    const handlerSetSelectTag = (item: any) => {
        store.dispatch({
            type: ADD_TAG,
            data: item
        })
        const filterTag = tagList.filter((tag: any) => item !== tag)
        setTagList(filterTag)
    };

    const handlerRemoveSelectTag = (item: any) => {
        store.dispatch({
            type: REMOVE_TAG,
            data: item
        });
        setTagList([item, ...tagList])
    };

    const handlerSearchTag = useCallback((value: any) => {
        setSelectTagForm((state: any) => ({
            ...state,
            tag: value,
        }))
    }, []);

    const handlerSelectTag = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        SelectTag(state.tags)
    }

    const handlerSetTagInputValue = useCallback((value: any) => {
        setSelectTagForm((state: any) => ({
            ...state,
            tag: value.target.innerHTML,
        }))
    }, [])

    useEffect(() => {
        fetchTagList()
        tagList.map((item: any) => {
            console.log(item?.title)
        })


    }, [selectTagForm])

    useEffect(() => {
        if (selectTagForm.tag && selectTagForm.tag.length > 0) {
            const firstChar = selectTagForm.tag.charAt(0).toUpperCase();
            setActiveLetter(firstChar);
        } else {
            setActiveLetter(null);
        }
    }, [selectTagForm.tag]);

    return (
        <Box className="auth-bg">
            <Grid container sx={{height: "100%"}}>

                <Grid item xs={1} sx={{
                    margin: {sm: "0 25px",},
                    display: "flex",
                    flexDirection: "column",
                    height: {xs: "initial", lg:"100%"},
                    justifyContent: "center",
                }}>
                    <Box>
                    {
                        alphabet.map((letter) => (
                        <Typography
                        key={letter}
                         className={`words ${activeLetter === letter ? 'active-word' : ''}`}
                         onClick={handlerSetTagInputValue}
                    >
                        {letter}
                    </Typography>
                    ))
                    }
        </Box>
</Grid>

    <Grid item xs={11} sm={9} md={10} sx={{scale: {md: "0.8", xs: "0.9"}, height: "100%"}}>
        <Typography sx={{marginBottom: '20px'}} variant="h4" className="login-header">Select
            Tags</Typography>
        <Stack sx={{mb: 2, width: '175', height: '82px'}}>
            <Logo/>
        </Stack>
        <Box component="form"
             onSubmit={handlerSelectTag}
             autoComplete="off"
             className="forget-password"
             sx={{
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "space-evenly",
                 height: "80%"
             }}>
            <Box sx={{textAlign: "center"}}>
                <AppInput value={selectTagForm?.tag} onChange={handlerSearchTag} type="search"/>
            </Box>
            <Box sx={{marginTop: "25px"}}>
                <AppSelectedTag handlerRemoveSelectTag={handlerRemoveSelectTag}/>
            </Box>
            <AppTags handlerSetSelectTag={handlerSetSelectTag}
                     tagList={tagList}
                     handlerChangePage={handlerChangePage}
                     pages={pages}/>
            <Box sx={{textAlign: "center"}}>
                <Typography className="find-tag" sx={{
                    marginTop: "20px"
                }}>
                    Did not find your Tag?<Button
                    style={{fontWeight: 700, fontSize: "15px", color: "#213555", textTransform: "none"}}
                    onClick={() => {
                        store.dispatch({
                            type: TOGGLE_PAGE
                        })
                    }}>Add custom tag</Button>
                </Typography>
            </Box>
            <Box sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}>
                <Box sx={{width: {md: "50%", xs: "90%"}}} className="done-button">
                    <AppButton type="submit" value="Done"/>
                </Box>
            </Box>
        </Box>
    </Grid>
</Grid>
</Box>
)
}

export default React.memo(AuthSelectTag)
