const ApiRouts = {
    SIGN_IN : "login",
    SIGN_IN_WITH_GOOGLE : "auth/google",
    SIGN_IN_WITH_FACEBOOK : "auth/facebook",
    VALIDATE_GOOGLE : "auth/google/callback",
    VALIDATE_FACEBOOK : "auth/facebook/callback",
    VERIFY_EMAIL : "verify-email",
    RESEND_EMAIL : "email/verification-notification",
    SIGN_UP:"register",
    INFORMATION:"profile/create",
    FORGET_PASSWORD:"forgot-password",
    RESET_PASSWORD:"reset-password",
    ENTER_TAG:"tags/create",
    SEARCH_TAG:"tags/search",
    REMOVE_TAG:"tags/remove",
    ADD_TAG:"tags/add",
    GET_SUGGESTED_TAG:"tags/suggested",
    GET_PROFILE:"profile",
    UPDATE_PROFILE:"profile/update",
    UPLOAD_AVATAR:"profile/update-avatar",
    POST_LIST:"posts",
    HOME_POST_LIST:"posts/home",
    EXPLORE_POST_LIST:"posts/explore",
    LIKE:"like",
    POST_DELETE:"posts/delete",
    COMMENT:"comment",
    BOOKMARKS:"bookmarks",
    TOGGLE_BOOKMARK:"bookmarks/toggle",
    NOTIFICATIONS:"notifications",
    UPLOAD_POST:"posts/create",
    CHAT_LIST:"chats",
    SEND_FILE:"chats/send-file",
    PUBLIC_CHAT_LIST:"chats/public",
    GROUP_CHAT_LIST:"chats/group",
    SEND_MESSAGE:"chats/send",
    SHARE_POST:"share",
    LEAVE_CHAT:"chats/leave",
    CHAT_READ:"chats/read",
    VOICE_MESSAGE:"chats/voice",
    GET_CHAT:"chat",
    JOIN_CHAT:"chats/join",
    DELETE_CHAT:"chats/delete",
    CHAT_REMOVE_PARTICIPANT:"chats/remove-participant",
    LOGOUT:"logout",
    RELATION_LIST:"followers",
    SWITCH_CLOSE_FRIEND:"close-friend",
    PENDING_LIST:"followers/pending",
    ACCEPT_REQUEST:"followers/accept",
    UNFOLLOW:"followers/unfollow",
    FOLLOW_REQUEST:"follow-request",
    SUGGESTED_RELATION:"followers/suggested",
    SHOW_OTHERS_PROFILE:"profile/show",
    UPDATE_SETTING:"settings/update",
    CREATE_DIRECT_CHAT:"chats/direct",
    SEARCH:"search",
}

export default ApiRouts
