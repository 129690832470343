import React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {MdFiberManualRecord} from "react-icons/md";

interface AppPaginationTags {
    onPageChange?: (page: number) => void;
    data: {
        current_page: number;
        last_page: number;
        links: {
            url: string | null;
            label: string;
            active: boolean;
        }[];
    };
}

const AppPaginationTags: React.FC<AppPaginationTags> = ({
                                                            onPageChange,
                                                            data,
                                                        }) => {
    const { current_page, last_page, links } = data;

    return (
        <Pagination
            page={current_page}
            hidePrevButton
            hideNextButton
            count={last_page}
            onChange={(_, page) => onPageChange ? onPageChange(page) : undefined}
            renderItem={(item) => (
                <PaginationItem
                    {...item}
                    component={(props: React.JSX.IntrinsicAttributes) => (
                        <MdFiberManualRecord
                            style={{
                                width: '15px',
                                height: '15px',
                                cursor: 'pointer',
                                color: item.page === current_page ? '#5f5fb4' : '#909AAA',
                            }}
                            {...props}
                        />
                    )}
                />
            )}
        />
    );
};

export default AppPaginationTags;
