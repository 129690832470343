// reducer.ts
import {ADD_TAG, REMOVE_TAG} from "./action";

interface TagsInterface {
    type: string;
    data: any
}

type ActionType = TagsInterface;

const initialized = () => {
    return []
};
const reducer = (state: any = initialized(), action: ActionType) => {

    const {data} = action;
    switch (action?.type) {
        case ADD_TAG:
            const item: any = [...state.filter((tag:any) => tag !== data), data]
            return item
        case REMOVE_TAG:
            return state.filter((tag: any) => tag.title !== data.title)
        default:
            return state;
    }
};

export default reducer;
