import {SUGGESTED_TAG} from "../actions";

interface SetInitialized {
    type: string;
    data: any
}

type ActionType = SetInitialized;

const initializedState = () =>{
    return []
};

const reducer = (state = initializedState(), action: ActionType) => {
    const { data } = action;
    switch (action?.type) {
        case SUGGESTED_TAG:
            return data;
        default:
            return state;
    }
};

export default reducer;
