import {AxiosResponse} from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {GET} from "../../api";
import ApiRouts from "../../boot/api-routes";
import {FETCH_BOOKMARK_LIST, SET_BOOKMARK_LIST} from "./action";

export function* FetchBookmarkList(){
    try{
        const response :AxiosResponse = yield call(GET , <string>ApiRouts?.BOOKMARKS, {})
        if(response){
            yield put({
                type:SET_BOOKMARK_LIST,
                data:response.data.data
            })
        }
    }catch(error){
        console.log(error)
    }
}


function* bookmarkSaga() {
    yield takeLatest(FETCH_BOOKMARK_LIST, FetchBookmarkList);
}

export default bookmarkSaga