import {Alert, Box, Grid, Link, Typography} from "@mui/material";
import AppButtonGmail from "../../components/AppButtonGmail";
import AppButtonFaceBook from "../../components/AppButtonFaceBook";
import AppButton from "../../components/AppButton";
import React, {useEffect} from "react";
import {SignIn} from "../../redux/auth/actions"
import AppLogo from "../../components/AppLogo";
import FormProvider from "../../components/HookForm/FormProvider";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import RHFTextField from "../../components/HookForm/RHFTextField";
import * as Yup from "yup";
import {PATH_AUTH} from "../../routes/paths";
import {useSelector} from "react-redux";

interface AppSignInForm {
    email: string,
    password: any
}

const AuthSignInForm = () => {
    const state: any = useSelector(state => state)
    const schema = yup.object().shape({
        email: Yup.string().required().max(128),
        password: Yup.string().required(),
    });
    const methods = useForm({resolver: yupResolver(schema)});

    const {
        handleSubmit,
        formState: {},
    } = methods;


    const handlerSignIn = async (data: any) => {
        SignIn(data.email, data.password)
    }

    return (
        <Box className="auth-bg">
            <Grid container sx={{height: "100%"}}>
                <Grid item
                      xs={10}
                      sm={6}
                      sx={{height: "100%", scale: "0.8"}}
                      mx="auto">
                    <FormProvider methods={methods} onSubmit={handleSubmit(handlerSignIn)} className="sign-form">
                        <Box sx={{textAlign: "center"}}>
                            <Typography variant="h4" className="login-header">Log in</Typography>
                        </Box>
                        <Box sx={{margin: "20px 0"}} className="flex justify-center">
                            <AppLogo/>
                        </Box>
                        {
                            state?.error !== null && state?.error.map((item: any) => (
                                <Alert severity="error" sx={{mb: 2}}>{item}</Alert>
                            ))
                        }
                        <Box sx={{marginBottom: "20px"}}>
                            <RHFTextField attribute={{
                                name: "email",
                                readOnly: false,
                                label: 'Username',
                                color: 'gray',
                                type: 'text',
                            }}/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <RHFTextField attribute={{
                                name: "password",
                                readOnly: false,
                                label: 'Password',
                                color: 'gray',
                                type: 'password',
                            }}/>
                        </Box>
                        <Box className="done-button">
                            <AppButton type="submit" value="Log in"/>
                        </Box>
                        <Typography sx={{
                            textAlign: "center",
                            marginTop: "35px",
                            marginBottom: "35px",
                            color: "#213555"
                        }}
                        >or</Typography>
                        <Box sx={{marginBottom: "15px"}}>
                            <AppButtonGmail/>
                        </Box>
                        <Box>
                            <AppButtonFaceBook/>
                        </Box>
                        <Box sx={{textAlign: "center", marginTop: "20px"}}>
                            <Typography>Do not have an account?
                                <Link style={{color: "#2E7EFF", textDecoration: "none"}} href={PATH_AUTH.register}> Sign
                                    Up</Link></Typography>
                            <Box sx={{paddingTop: "15px"}}>
                                <Typography>
                                    <Link
                                        style={{color: "#2E7EFF", textDecoration: "none"}}
                                        href={PATH_AUTH.forgotPassword}> Forgot password?</Link>
                                </Typography>
                            </Box>
                        </Box>

                    </FormProvider>
                </Grid>
            </Grid>
        </Box>
    )
}

export default React.memo(AuthSignInForm)
